@import url('https://fonts.googleapis.com/css2?family=Anton&family=Patrick+Hand&family=Poppins:wght@100;400&family=Roboto&display=swap');
:root {
  --primaryColor: #247cff;
  --secondaryColor: #5c687a;
  --primaryFillColor: #247cff;
  --textBlack: rgba(54, 54, 54, 1);
  --hoverPrimaryBtn: #136ef7;
  --formShadow: rgba(96, 168, 48, 0.25);
  --textSecondary: #3d3d3d;
}

.labelHeading {
  font-size: 16px;
  font-weight: 500;
  color: #262729;
}
.profile-logo {
  width: 100px; 
  height: 100px; 
  border-radius: 50%;
  object-fit: cover;
}

:where(.css-dev-only-do-not-override-14i19y2).ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: #fff !important;
}

.custom-modal .ant-modal-header .ant-modal-title {
  font-size: 20px;
  font-family: Roboto !important;
  color: var(--secondaryColor);
  font-weight: 500;
}

.custom-modal .ant-modal-body {
  padding: 24px;
  font-size: 20px;
}

.green {
  color: var(--color-47ad24);
  font-size: 14px;
  font-family: Roboto !important;
}

.red {
  color: var(--color-212529);
  font-size: 14px;
  font-family: Roboto !important;
}

.quill {
  background-color: white;
}

.form-control {
  box-shadow: none;
  border: 1px solid lightgray;
}

.form-multiselect {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-multiselect .ant-select-selector {
  overflow: auto;
}


.lablesHeading {
  font-size: 16px;
  padding-bottom: 5px;
  font-family: Roboto !important;
  color: var(--secondaryColor);
  font-weight: 400;
}

.title {
  font-size: 24px;
  font-family: Roboto !important;
  color: var(--secondaryColor);
  font-weight: 500;
}

.sub-title {
  font-size: 20px;
  font-family: Roboto !important;
  color: var(--secondaryColor);
  font-weight: 500;
}

.desc {
  font: 14px;
  font-family: Roboto !important;
  color: var(--color-828599);
}

.nav-link {
  font-size: 1.2rem;

  color: #495057;
  font-weight: 600;
}


.time-slot-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.time-slot {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* background-color: #f5f5f5; */
  /* color: #333; */
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex: 1;

}

.time-slot:hover {
  background-color: #e0e0e0;
}

.selected {
  background-color: #007bff;
  color: white;
  border: 1px solid #0056b3;
}



* {
  font-family: 'Poppins', sans-serif !important;
}

html {
  overflow: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primaryColor);
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--hoverPrimaryBtn);
}

.pointer {
  cursor: pointer;
}

.css-cveggr-MuiListItemIcon-root {
  min-width: 28px !important;
}

.css-1ygcj2i-MuiTableCell-root {
  font-weight: 600 !important;
  color: var(--textSecondary) !important;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  display: flex !important;
  align-items: baseline !important;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  z-index: 1045 !important;
}

.css-1e797rr-MuiPaper-root-MuiAppBar-root {
  z-index: 1045 !important;
}

.css-1l8j5k8 {
  z-index: 1045 !important;
}
.drawer{
  width: 450px !important;
}
.css-1yw475k {
  z-index: 1045 !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 5px !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 7.2px !important;
  border-radius: 5px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  /* height: auto; */
  height: 25px !important;
  text-overflow: ellipsis;
  white-space: unset !important;
  overflow: hidden;
  max-height: 25px !important;
  font-size: 14px !important;
}

.css-jtlhu6-MuiTablePagination-root {
  overflow: hidden !important;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
}

.css-qiwgdb {
  padding: 7.5px 14px !important;
}

.activeTab {
  background: var(--primaryColor) !important;
  color: white !important;

  svg {
    color: white !important;
  }
}

.activeTab2 {
  border-bottom: 2px solid var(--primaryColor);
}

.transition-2 {
  transition: 0.3s all !important;
}


.table-container {
  width: 100%;
  overflow-x: auto;
  position: absolute;

  .table {
    width: 100%;
    min-width: 1400px !important;
  }
}

/* width */
.table-container::-webkit-scrollbar {
  height: 12px;
}

/* Track */
.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.table-container::-webkit-scrollbar-thumb {
  background: #b6b1b1;
}

/* Handle on hover */
.table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}



.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 0.8125rem !important;
}

.f-14 {
  font-size: 0.875rem !important;
}

.f-15 {
  font-size: 15px !important;
}

/* font weight start  */

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

/* font weight end  */


/* buttons style start  */

.btn-primary {
  background: var(--primaryColor) !important;
  border-color: var(--primaryColor) !important;
  color: white !important;
}

.btn-primary:hover {
  background: var(--hoverPrimaryBtn) !important;
  border-color: var(--hoverPrimaryBtn) !important;
  color: white !important;
}

/* buttons style end  */


/* text style start  */



.text-primary {
  color: var(--primaryColor) !important;
}

.text-secondary {
  color: var(--textSecondary) !important;
}

.text-gray {
  color: gray !important;
}

.text-black {
  color: var(--textBlack);
}

.text-white {
  color: white !important;
}

/* text style end  */




/* form style start  */

.form-check-input:checked {
  background-color: var(--primaryColor) !important;
  border-color: var(--primaryColor) !important;
}

input[type='checked']:checked {
  background-color: var(--primaryColor) !important;
  border-color: var(--primaryColor) !important;
}

.form-check-input:focus {
  border-color: var(--primaryColor) !important;
  outline: 0 !;
  box-shadow: 0 0 0 .25rem var(--formShadow) !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus {
  border-color: var(--primaryColor) !important;
  outline: 0 !important;
  box-shadow: 0 0 0 .25rem var(--formShadow) !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: var(--primaryColor) !important;
}

.form-control:focus {
  border-color: var(--primaryColor) !important;
  outline: 0;
  box-shadow: 0 0 0 .25rem var(--formShadow) !important;
}

.form-select:focus {
  border-color: var(--primaryColor) !important;
  outline: 0;
  box-shadow: 0 0 0 .25rem var(--formShadow) !important;
}

select option:hover{

  background: var(--primaryColor) !important;
}

/* form style end  */


.p-relative {
  position: relative;
}

.pendingStatus{
  color: black ;
  background: rgba(255, 255, 0, 0.2);
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 10px;
}
.rejectedStatus{
  color: red ;
  background: rgba(255, 0, 0, 0.2);
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 10px;
}
.approvedStatus{
  color: green;
  background: rgba(0, 128, 0, 0.2);
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 10px;
}


.p-treetable-table{
 
  td{
    border-bottom: 1px solid rgb(231, 227, 227) !important;
  }
}

.notifier {
  position: relative;
  display: inline-block;
}

.bell {
  font-size: 26px;
  color: #FFD700;
  transition: 0.3s;
}

.badge {
  position: absolute;
  top: -5px;
  left: 24px;
  padding: 0 5px;
  font-size: 14px;
  line-height: 22px;
  height: 22px;
  background: #EF476F;
  color: #FFF;
  border-radius: 11px;
  white-space: nowrap;
}

.unreadBandag{
  background: #EF476F;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}


.notifier.new .badge {
  animation: pulse 2s ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  40% {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.3, 1.3, 1.3);
  }

  55% {
    transform: scale3d(1, 1, 1);
  }
  
  60% {
    transform: scale3d(1.3, 1.3, 1.3);
  }

  65% {
    transform: scale3d(1, 1, 1);
  }
}



/* review status  */

.statusOuterr {
  background: rgba(241, 198, 3, 0.2);
  padding: 4px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.statusDotr {
  width: 12px;
  height: 12px;
  background: rgb(241, 198, 3);
  border-radius: 100%;
}

.Statusr {
  font-size: 16px;
  color: #012A3E;
  font-weight: 500;
  text-wrap: nowrap;
}

/* disapproved status  */

.statusOuterin {
  background: rgba(237, 85, 101, 0.2);
  padding: 4px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.statusDotin {
  width: 12px;
  height: 12px;
  background: rgba(237, 85, 101, 1);
  border-radius: 100%;
}

.Statusin {
  font-size: 16px;
  color: #012A3E;
  font-weight: 500;
  text-wrap: nowrap;
}



/* approved status  */

.statusOuterA {
  background: rgba(111, 151, 92, 0.2);
  padding: 4px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.statusDotA {
  width: 12px;
  height: 12px;
  background: rgba(111, 151, 92, 1);
  border-radius: 100%;
}

.StatusA {
  font-size: 16px;
  color: rgba(111, 151, 92, 1);
  font-weight: 500;
}


.uploadIcon{
  font-size: 3rem;
}

.fileContainer{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}

.fileStyle{
  width: 100px;
  height: 100px;
  /* display: flex; */
}


.profilePreview{
  border-radius: 100%;
  width: 150px;
  height: 150px;
  border: 1px solid grey;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.uploadtext{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: lightgray;
  font-size: 1.2rem;
}

.list-style{
  list-style: none;
  border: 1px solid rgb(222, 216, 216);
  display: flex;
  flex-direction: column;
  padding: 3px 0px;
}

.list-style li{
  padding: 7px 5px;
}
.list-style li:hover{
  background: #efebeb;
  cursor: pointer;
}